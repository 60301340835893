import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import styled from 'styled-components';
import BlogPostList from '../components/BlogPostList';
import { BlogPageLayout } from '../components/Layout';
import Newsletter from '../components/Newsletter';
import { Section } from '../components/Section';
import Title from '../components/Title';

const BlogPageWrapper = styled.div`
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const BlogListWrapper = styled.div<{ mobile?: boolean }>`
    // width: ${props => (props.mobile ? '100%' : '70%')};
    width: 100%;
    flex-grow: 0;
    text-align: left;
`;

const BlogSidebarWrapper = styled.div<{ mobile?: boolean }>`
    display: ${props => (props.mobile ? 'none' : 'block')};
    width: calc(30% - 1.5rem);
    text-align: left;
`;

const BlogListHero = styled.div`
    padding: 6rem 0;
    text-align: center;
`;

export const Blog = () => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;
    return (
        <BlogPageLayout>
            <Section hideBottomMargin={true} maxWidth="675px">
                <BlogListHero>
                    <Title
                        value="Blog"
                        subTitle="Documenting my experiences as a full-stack developer"
                        subTitleFontStyle="secondary"
                        accent={false}
                    />
                </BlogListHero>
                <BlogPageWrapper>
                    <BlogListWrapper mobile={mobile}>
                        <BlogPostList categoryToFilter="freelancing" />
                    </BlogListWrapper>
                    {/* <BlogSidebarWrapper mobile={mobile}>
                        <BlogListCategories />
                    </BlogSidebarWrapper> */}
                    <Newsletter />
                </BlogPageWrapper>
            </Section>
        </BlogPageLayout>
    );
};

export default Blog;
