import { graphql, navigate, StaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import Title from './Title';

const Image = styled(GatsbyImage)`
    border-radius: 6px;
    margin-bottom: 1.5rem;
`;

const BlogPostItemWrapper = styled.div<{ dualColumn: boolean; boxed: boolean; mobile: boolean }>`
    padding-bottom: 3rem;
    text-align: left;

    ${props => {
        if (props.dualColumn && props.mobile)
            return `
                width: 100%;
                padding: 1.5rem;
                margin-bottom: 1.5rem;
            `;
        else if (props.dualColumn && !props.mobile)
            return `
            width: calc(50% - 4.5rem);
            padding: 1.5rem;
            margin-bottom: 3rem;
        `;
    }}

    ${props => {
        if (props.boxed)
            return `
                -webkit-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
                -moz-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
                box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
                background-color: var(--bgSecondary);
                color: var(--textNormal);
                border: var(--bgSecondary);
                cursor: pointer;
                border-radius: 6px;
            `;
    }}

    &:hover {
        cursor: pointer;
        h2,
        a {
            color: #0652dd;
        }
    }
`;

const BlogPostItemExcerpt = styled.div`
    margin-bottom: 1.5rem;
`;

const BlogPostItemReadMore = styled.div`
    font-weight: bold;

    a,
    a:visited {
        text-decoration: none;
        color: var(--textColor);
    }
`;

const BlogPostListItem = ({
    post,
    dualColumn,
    boxed,
    mobile,
}: {
    post: Blog.PostNode;
    dualColumn: boolean;
    boxed: boolean;
    mobile: boolean;
}) => {
    const image = getImage(post?.frontmatter?.cover);
    return (
        <BlogPostItemWrapper
            onClick={() => navigate(post.fields?.slug!)}
            dualColumn={dualColumn}
            boxed={boxed}
            mobile={mobile}
        >
            {image && <Image image={image} alt={post?.frontmatter?.title!} />}
            <Title value={post.frontmatter?.title} accent={false} alignment="left" size="h2" marginTop={0} />
            <BlogPostItemExcerpt>{post.frontmatter?.excerpt}</BlogPostItemExcerpt>
            <BlogPostItemReadMore>
                <a href="#">Read more</a>
            </BlogPostItemReadMore>
        </BlogPostItemWrapper>
    );
};

export const BlogPostList = ({
    categoryToFilter,
    dualColumn = false,
    boxed = false,
    mobile = false,
}: {
    categoryToFilter?: string;
    dualColumn?: boolean;
    boxed?: boolean;
    mobile?: boolean;
}) => {
    return (
        <StaticQuery
            query={graphql`
                query AllBlogPostQuery {
                    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
                        nodes {
                            id
                            excerpt
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                date
                                excerpt
                                author
                                category
                                cover {
                                    childImageSharp {
                                        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={(data: Blog.AllMDX) => (
                <>
                    {data.allMdx?.nodes?.map((postNode, index) => {
                        if (categoryToFilter) {
                            const postCategory = postNode.frontmatter?.category?.toLowerCase();
                            if (postCategory === categoryToFilter)
                                return (
                                    <BlogPostListItem
                                        key={`blog-post-${index}`}
                                        post={postNode}
                                        dualColumn={dualColumn}
                                        boxed={boxed}
                                        mobile={mobile}
                                    />
                                );
                            else return undefined;
                        } else
                            return (
                                <BlogPostListItem
                                    key={`blog-post-${index}`}
                                    post={postNode}
                                    dualColumn={dualColumn}
                                    boxed={boxed}
                                    mobile={mobile}
                                />
                            );
                    })}
                </>
            )}
        />
    );
};

export default BlogPostList;
